<template>
  <div v-if="pending">
    <Loader />
  </div>
  <div
    v-else-if="error"
    class="text-danger text-center pt-4"
  >
    <i
      class="fas fa-xmark"
      style="font-size: 25px"
    />
    <div>
      {{ $t('error.fail') }}
    </div>
  </div>
  <div v-else>
    <div
      class="d-flex justify-content-between align-items-center mt-1"
    >
      <h5 class="payer-section mb-0">
        {{ $t('bill.changeHistory') }}
      </h5>
    </div>
    <hr class="mt-2">

    <div
      v-if="list.length > 0"
      class="d-flex justify-content-end text-secondary small text-right pb-1"
      style="font-size: 11px"
    >
      <div
        style="width: 120px"
        class="pr-1"
      >
        {{ $t('report.balance.paid') }}
      </div>
      <div
        style="width: 120px"
        class="pr-1"
      >
        {{ $t('report.balance.due') }}
      </div>
    </div>

    <div>
      {{ list }}
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    scope: Object,
  },
  data: () => ({
    list: [],
    error: false,
    pending: false,
  }),
  watch: {
    scope() {
      this.request();
    },
  },
  methods: {
    ...mapActions(['getPaymentsAudit']),
    request() {
      this.getPaymentsAudit({
        params: {
          query: {
            from: this.scope.from,
            to: this.scope.to,
          },
        },
      })
        .then(({ data }) => {
          this.list = data;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>

.payer-section {
  font-size: 16px;
  font-weight: 600 !important;
}

</style>
