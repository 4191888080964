<template>
  <div>
    <div style="margin: 5px">
      <label class="d-flex">
        {{ $t('settings.emailAdress') }}

      </label>
      <BFormInput
        v-model="email"
        :disabled="!!user"
        :placeholder="$t('settings.emailAdress')"
        size="sm"
      />
    </div>

    <hr>
    <template v-if="!newRole">
      <div
        v-for="role in userRoles"
        :key="role.id"
        class="role-row d-flex align-items-center"
        :class="{ active: toRemove.includes(role.id) || role.added }"
      >
        <div class="pr-2">
          <i :class="role.type.icon" />
        </div>
        <div
          class="flex-grow-1 small"
          :class="{ 'text-strikethrough': toRemove.includes(role.id) }"
        >
          <div>
            {{ role.type.text }}
          </div>
          <div class="text-secondary">
            {{ getScopeName(role.type.key, role.scope) }}
          </div>
        </div>

        <i
          class="role-remove"
          :class="toRemove.includes(role.id) ? 'fas fa-rotate-left' : 'fas fa-trash-alt'"
          @click="toggleRemove(role)"
        />
      </div>
    </template>
    <div
      v-if="!newRole"
      class="text-right mt-2"
    >
      <button
        class="btn btn-sm btn-secondary"
        @click="newRole = { added: true }; $emit('update:pending', true)"
      >
        <i class="fas fa-plus pr-2" />
        {{ $t('general.add') }}
      </button>
    </div>
    <div
      v-if="newRole"
      class="position-relative"
      style="padding-top: 20px"
    >
      <button
        type="button"
        aria-label="Close"
        class="close position-absolute"
        style="font-size: 30px; top: 0; right: 0"
        @click="cancel()"
      >
        ×
      </button>
      <BoxSelect
        :selected.sync="newRole.type"
        :fields="roleTypes"
        class="mt-2"
      />
      <div class="m-1">
        <BFormSelect
          v-if="newRole.type === 'PayersView'
            || newRole.type === 'ShopOrdersView'"
          v-model="newRole.scope"
          size="sm"
          :options="groupOptions"
        />
      </div>
      <div class="text-right">
        <button
          class="btn btn-primary btn-sm"
          type="button"
          @click="newRoles.push(newRole); cancel()"
        >
          {{ $t('general.confirm') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import BoxSelect from '@/components/BoxSelect';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    user: Object,
    tenantId: String,
    pending: Boolean,
  },
  data: () => ({
    email: '',
    list: [],
    newRoles: [],
    toRemove: [],
    type: 'PayeeAdmin',
    newRole: null,
  }),
  components: {
    BoxSelect,
  },
  computed: {
    ...mapGetters(['roleTypes', 'groups', 'payeeId']),
    userRoles() {
      return this.list.concat(this.newRoles)
        .map((x) => ({
          ...x,
          id: `${x.type}_${x.scope}`,
          type: this.roleTypes.find((y) => y.key === x.type),
        }));
    },
    groupOptions() {
      return (this.groups || [])
        .map((x) => ({
          value: `${this.payeeId}|${x.code}`,
          text: x.code === '#all'
            ? this.$t('company.all')
            : x.path,
        }));
    },
  },
  methods: {
    ...mapActions([
      'addPayeeRole',
      'removePayeeRole',
    ]),
    cancel() {
      this.$emit('update:pending', false);
      this.newRole = null;
    },
    toggleRemove({ id, added }) {
      if (added) {
        this.newRoles = this.newRoles.filter((x) => `${x.type}_${x.scope}` !== id);
        return;
      }

      if (this.toRemove.includes(id)) {
        this.toRemove = this.toRemove.filter((x) => x !== id);
      } else {
        this.toRemove.push(id);
      }
    },
    getScopeName(key, scope) {
      if (key === 'PayersView' || key === 'ShopOrdersView') {
        const parts = scope.split('|');
        return this.groups.find((x) => x.code === parts[1])?.name ?? '?';
      }
      return '';
    },
    submit() {
      const email = this.user?.email || this.email;
      const removals = this.toRemove
        .map((id) => this.userRoles.find((y) => id === y.id))
        .map((role) => this.removePayeeRole({
          params: {
            tenantId: this.tenantId,
          },
          data: {
            email,
            roleType: role.type.key,
            scope: role.scope,
            payeeId: this.payeeId,
          },
        }));

      const additions = this.newRoles
        .map((role) => this.addPayeeRole({
          params: {
            tenantId: this.tenantId,
          },
          data: {
            email,
            roleType: role.type,
            scope: role.scope,
            payeeId: this.payeeId,
          },
        }));

      this.$emit('update:pending', true);
      Promise.all([
        ...removals,
        ...additions,
      ])
        .then(() => {
          this.$emit('refresh');
          this.$emit('update:pending', false);
        });
    },
    init() {
      if (this.user) {
        this.email = this.user.email;
        this.list = (this.user.list || []).slice();
      }
      this.toRemove = [];
      this.newRoles = [];
      this.newRole = null;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.role-row {
  padding: 5px 10px;
  border: 2px solid #eee;
  border-radius: 15px;
  margin: 5px;

  &.active {
    border-color: $blue;
    background-color: rgba($blue, 0.1);
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.role-remove {
  cursor: pointer;

  &:hover {
    color: $red;
  }
}
</style>
