<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="600"
    placement="bottom"
    :append-to="() => tooltipParent"
    @show="opened"
  >
    <template #trigger>
      <slot
        name="trigger"
        :selected="selected"
      >
        <div class="sort-option-selected">
          <div
            class="font-weight-bold text-secondary"
            style="font-size: 10px; min-width: 180px"
          >
            {{ $t('menu.payers') }}
          </div>
          <div
            v-if="selected.length === 0"
            style="font-size: 14px;"
          >
            -
          </div>
          <div
            v-else-if="onlyGroups"
            class="small"
          >
            <span
              v-for="(s, i) in onlyGroupsNames"
              :key="s.id"
            ><span v-if="i > 0">, </span>{{ s }}</span>
          </div>
          <div
            v-else-if="selected.length <= showInline"
            class="small pr-4"
          >
            <span
              v-for="(s, i) in selected"
              :key="s.id"
            ><span v-if="i > 0">, </span>{{ s.name }}</span>
          </div>
          <div
            v-else
            class="first d-flex pr-2"
            style="font-size: 14px"
          >
            <div class="flex-grow-1">
              {{ selected.length }}
            </div>
          </div>

          <i
            v-if="selected.length > 0"
            class="far fa-close close-btn"
            @click.stop="clear"
          />
        </div>
      </slot>
    </template>

    <div
      class="row"
      style="width: 550px"
    >
      <div
        class="col-5 pt-1"
        style="width: 200px; max-height: 438px; overflow: auto"
      >
        <div style="padding-bottom: 5.5px">
          <SearchBox
            ref="search"
            v-model="groupSearch"
            :height="30"
            constant-width
          />
        </div>
        <PayersGroupFilterList
          :groups="filteredGroupOptions"
          :selected="groupFilter"
          :select="selectGroup"
          :root-id="'#all'"
          :hide-size="true"
          :expand="!!groupSearch"
        />
      </div>
      <div
        class="col-7 pt-1"
        style="max-width: 350px"
      >
        <div style="padding-bottom: 5.5px">
          <SearchBox
            ref="search"
            v-model="search"
            :height="30"
            constant-width
          />
        </div>
        <div
          style="max-height: 400px; overflow: auto"
        >
          <div
            v-if="groupFilter !== '#all' && search.length == 0"
            class="sort-option text-left d-flex align-items-center pl-0"
            @click="toggleGroup(groupFilter)"
          >
            <i
              v-if="!groupPending"
              class="fal fa-down pr-2"
              style="padding-left: 10px"
            />
            <Loader
              v-else
              size="10px"
            />
            {{ $t('groups.everyoneInGroup') }} {{ groupName }}
          </div>
          <div
            v-for="s in filteredPayers"
            :key="s.key"
            class="sort-option text-left d-flex pl-0"
            :class="{
              active: isSelected(s.id)
            }"
            @click="toggle(s)"
          >
            <Checkbox
              v-if="!addOnly"
              :value="isSelected(s.id)"
            />
            <div class="flex-grow-1 pl-1">
              {{ s.name }}

              <small
                v-if="s.code"
                class="text-secondary"
              >| {{ s.code }}</small>
            </div>
          </div>
          <InfinityScrollCursor
            ref="cursor"
            :margin="300"
            @request="request"
          />

          <div v-if="busy">
            <Loader />
          </div>
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import Checkbox from '@/components/utils/Checkbox';
import createSearch from '@/utils/search';
import { mapActions, mapGetters } from 'vuex';
import PayersGroupFilterList from './PayersGroupFilterList';

export default {
  props: {
    payerIds: Array,
    addOnly: Boolean,
    showInline: {
      default: 3,
      type: Number,
    },
    tooltipParent: {
      type: Element,
      default: () => document.body,
    },
  },
  data: () => ({
    payers: [],
    selected: [],
    search: '',
    groupFilter: '#all',
    skip: 0,
    take: 20,
    groupPending: false,
    bottom: false,
    busy: false,
    requestId: 0,
    onlyGroups: true,
    onlyGroupsList: [],
    groupSearch: '',
  }),
  components: {
    Checkbox,
    InfinityScrollCursor,
    PayersGroupFilterList,
  },
  computed: {
    ...mapGetters(['groups']),
    groupName() {
      return this.groups.find((x) => x.code === this.groupFilter)?.path;
    },
    filteredGroupOptions() {
      if (!this.groupSearch) {
        return this.groupOptions;
      }

      const search = createSearch(this.groupSearch);
      const filteredGroups = this.groupOptions.filter((x) => search(x.path));

      const foundCodes = filteredGroups
        .flatMap((x) => this.getParents(x.code));

      return this.groupOptions
        .filter((g) => foundCodes.includes(g.code));
    },
    groupOptions() {
      return (this.groups || [])
        .map((x) => ({
          key: x.code,
          code: x.code,
          text: x.code === '#all'
            ? this.$t('company.all')
            : x.name,
          path: x.code === '#all'
            ? this.$t('company.all')
            : x.path,
          size: x.size,
          parentCode: x.parentCode,
        }));
    },
    filteredPayers() {
      if (!this.addOnly) return this.payers;
      return this.payers.filter((x) => !this.payerIds.includes(x.id));
    },
    onlyGroupsNames() {
      return this.groups
        .filter((x) => this.onlyGroupsList.includes(x.code))
        .map((x) => x.name)
        .sort((a, b) => a.localeCompare(b));
    },
  },
  watch: {
    payerIds(ids) {
      this.selected = this.selected.filter((x) => ids.includes(x.id));
    },
    search() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
    groupFilter() {
      this.skip = 0;
      this.bottom = false;
      this.busy = false;
      this.payers = [];
      this.requestId += 1;
      this.request();
    },
    selected(v) {
      if (v.length === 0) {
        this.onlyGroups = true;
        this.onlyGroupsList = [];
      }
    },
  },
  methods: {
    ...mapActions([
      'getPayers',
      'getPayersByIds',
    ]),
    clear() {
      this.selected = [];
      this.$emit('selected', this.selected);
    },
    getParents(code) {
      if (!code || code === '#all') {
        return [];
      }

      const group = this.groupOptions.find((x) => x.code === code);
      return [code, ...this.getParents(group.parentCode)];
    },
    selectGroup(key) {
      this.groupFilter = key;
    },
    setPayers(payers) {
      this.selected = payers;
      this.$emit('selected', this.selected);
    },
    open() {
      this.$refs.popover.tippy().show();
    },
    toggle(s) {
      this.onlyGroups = false;
      this.onlyGroupsList = [];

      if (this.isSelected(s.id)) {
        this.selected = this.selected.filter((x) => x.id !== s.id);
        this.$emit('selected', this.selected);
      } else {
        this.selected.push(s);
        this.$emit('selected', this.selected);
      }

      if (this.addOnly) {
        this.$refs.popover.tippy().hide();
      }
    },
    isSelected(id) {
      return this.selected.some((x) => x.id === id);
    },
    toggleGroup(groupKey) {
      if (this.onlyGroups) {
        this.onlyGroupsList.push(groupKey);
      }

      this.groupPending = true;
      this.getPayers({
        params: {
          query: {
            skip: 0,
            take: -1,
            sort: 'name',
            group: groupKey,
          },
        },
      })
        .then(({ data }) => {
          this.setPayers(data);
        })
        .finally(() => {
          this.groupPending = false;
        });
    },
    opened() {
      this.$nextTick(() => {
        if (this.$refs.search) {
          this.$refs.search.focus();
        }
      });
      this.request();
    },
    request() {
      if (this.bottom || this.busy) return;
      this.busy = true;
      this.error = null;
      const id = this.requestId;

      this.getPayers({
        params: {
          query: {
            skip: this.skip,
            take: this.take,
            group: this.groupFilter === '#all'
              ? undefined
              : this.groupFilter,
            search: this.search.length === 0
              ? undefined
              : this.search,
          },
        },
      })
        .then(({ data }) => {
          if (id !== this.requestId) {
            return;
          }
          this.skip += this.take;

          const uniquePayers = data.filter((x) => !this.payers.some((y) => y.id === x.id));
          this.payers = [...this.payers, ...uniquePayers];

          if (data.length === 0) {
            this.bottom = true;
            this.busy = false;
          }
          setTimeout(() => {
            this.busy = false;
          }, 500);
        })
        .catch(() => {
          this.error = true;
        });
    },
    init() {
      if (!this.payerIds || this.payerIds.length === 0) return;
      this.getPayersByIds({
        params: {
          query: {
            ids: this.payerIds,
          },
        },
      })
        .then(({ data }) => {
          this.selected = data;
        });
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
 @import './style.scss';

  .sort-option-selected {
    position: relative;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    font-size: 20px;
    width: 24px;
    right: 10px;
    top: 11px;
    background-color: rgba(#ddd, 0.4);
    padding: 2px;
    text-align: center;
    border-radius: 50%;
    color: #888;

    &:hover {
      color: #333;
      background-color: #ddd;
    }
  }
</style>
