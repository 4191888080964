<template>
  <div
    class="pt-2"
  >
    <div class="d-flex justify-content-between align-items-center p-2">
      <div class="font-weight-bold">
        {{ $t('client.basket') }}
      </div>
      <div>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="close"
        >
          ×
        </button>
      </div>
    </div>
    <hr class="mt-0">

    <div style="max-width: 1000px; padding-bottom: 100px;">
      <div
        v-if="basketGroups.length > 1"
        class="setting-description font-weight-bold d-flex"
        style="font-size: 14px"
      >
        <div>
          <div class="info-wrapper d-flex align-items-center justify-content-center">
            <i class="fas fa-info" />
          </div>
        </div>
        <div class="text-left">
          {{ $t('client.groupsInfo') }}
        </div>
      </div>
      <div
        v-for="g in basketGroups"
        :key="g.key"
      >
        <BasketGroup
          v-bind="g"
          :payee-setup="getPayee(g.payeeId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BasketGroup from '../components/BasketGroup';

export default {
  components: {
    BasketGroup,
  },
  computed: {
    ...mapGetters(['userPayers', 'basketGroups']),
  },
  watch: {
    basketGroups() {
      if (this.basketGroups.length === 0) {
        this.close();
      }
    },
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    getPayee(payeeId) {
      return this.userPayers.find((x) => x.payeeId === payeeId);
    },
  },
  mounted() {
    if (this.basketGroups.length === 0) {
      this.close();
    }
  },
  created() {
    this.$emit('page', 'home');
  },
};
</script>

<style lang="scss" scoped>
.close {
  font-size: 30px;
}

.setting-description {
  border-radius: 15px;
  border: 2px solid $blue;
  padding: 0.7rem;
  background-color: rgba(lighten($darkblue, 57), 0.9);
  color: $darkblue;

  .info-wrapper {
    background-color: $darkblue;
    color: lighten($darkblue, 57);
    border-radius: 50%;
    text-align: center;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.7rem;

    i {
      font-size: 0.75rem;
    }
  }
}
</style>
