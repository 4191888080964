<template>
  <div>
    <div style="margin: 5px">
      <label style="font-size: 12px;">
        {{ $t('settings.bankAccounts.typeAccName') }}
      </label>
      <BFormInput
        v-model="accountName"
        :placeholder="$t('settings.bankAccounts.typeAccName')"
        size="sm"
      />
    </div>
    <div style="margin: 10px 5px 0px">
      <div
        class="d-flex align-items-center"
      >
        <label style="font-size: 12px">
          {{ $t('settings.bankAccounts.accNumber') }}
        </label>
        <div style="margin-bottom: 5px">
          <TooltipHelp class="pl-2">
            <div v-html="$t('settings.bankAccounts.specialCharactersInfo')" />
          </TooltipHelp>
        </div>
      </div>
      <BFormInput
        v-model="accountNumber"
        :placeholder="$t('settings.bankAccounts.typeAccNumber')"
        size="sm"
        :disabled="accNumberDisabled"
        :state="accNumberDisabled ? null : accountNumber.replace(/\s/g, '').length == 26"
        @keyup="check()"
        @focusout="format()"
      />
    </div>

    <div style="margin: 10px 5px 0px">
      <div
        class="d-flex align-items-center"
      >
        <label style="font-size: 12px">
          {{ $t('settings.transferPrefix') }}
        </label>
        <div style="margin-bottom: 5px">
          <TooltipHelp class="pl-2">
            Każdy przelew, wykonany przez Twoich klientów w Pagaspot przyjdzie na Twój rachunek
            firmowy z tytułem takim jak poniżej. Użyj "[clients]" aby umieścić
            w tytule przelewu imiona i nazwiska opłacanych klientów. Oraz "[bill-codes]" aby zawrzeć
            kody naliczeń
          </TooltipHelp>
        </div>
      </div>
      <BFormInput
        v-model="defaultTransactionPrefix"
        :placeholder="$t('settings.transferPrefix')"
        size="sm"
      />
    </div>
    <hr>
    <div
      v-if="!accountNumber.includes('_')"
      class="mt-2"
    >
      <BFormCheckbox
        v-model="isDefault"
        style="line-height: 1.8"
        class="d-flex align-items-center"
      >
        {{ $t('general.default') }}
      </BFormCheckbox>
    </div>

    <div
      class="mt-2"
    >
      <BFormCheckbox
        v-model="isVisible"
        style="line-height: 1.8"
        class="d-flex align-items-center"
      >
        {{ $t('settings.bankAccounts.visible') }}
      </BFormCheckbox>
    </div>

    <!-- (!selected.isRejected && !selected.isVerified) -->
    <template v-if="isAdmin && selected">
      <hr>
      <div class="d-flex">
        <div class="col">
          <button
            class="btn btn-block btn-sm btn-danger"
            :disabled="verificationPending"
            type="button"
            @click="reject"
          >
            Reject
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-block btn-sm btn-success"
            :disabled="verificationPending"
            type="button"
            @click="verify"
          >
            Accept
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { accountNumber } from '@/utils/filters';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    pending: Boolean,
    selected: Object,
  },
  data: () => ({
    accountNumber: '',
    accountName: '',
    isDefault: false,
    isVisible: true,
    errVerification: false,
    selectedBankAccount: null,
    accNumberDisabled: false,
    defaultTransactionPrefix: '',
    verificationPending: false,
    error: '',
  }),
  computed: {
    ...mapGetters(['isAdmin']),
  },
  watch: {
    accountNumber() {
      this.validate();
    },
    accountName() {
      this.validate();
    },
  },
  methods: {
    ...mapActions([
      'addBankAccount',
      'updateBankAccount',
      'deleteBankAccount',
      'verifyBankAccount',
      'rejectBankAccount',
    ]),
    verify() {
      this.verificationPending = true;
      this.verifyBankAccount({
        params: {
          accountNumber: this.selected.accountNumber,
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .finally(() => {
          this.verificationPending = false;
        });
    },
    reject() {
      this.verificationPending = true;
      this.rejectBankAccount({
        params: {
          accountNumber: this.selected.accountNumber,
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .finally(() => {
          this.verificationPending = false;
        });
    },
    validate() {
      if (this.accountName.length > 0 && this.accountNumber.replace(/\s/g, '').length === 26) {
        this.$emit('valid', true);
      } else {
        this.$emit('valid', false);
      }
    },
    check() {
      this.accountNumber = this.accountNumber.replace(/[^\d^_ ]/g, '');
    },
    format() {
      this.accountNumber = accountNumber(this.accountNumber.replace(/\s/g, ''));
    },
    submit() {
      if (this.selected) {
        this.update();
      } else {
        this.add();
      }
    },
    add() {
      this.$emit('update:pending', true);
      this.addBankAccount({
        data: {
          accountNumber: this.accountNumber.replace(/\s/g, ''),
          name: this.accountName,
          isDefault: this.isDefault,
          isVisible: this.isVisible,
          paymentTitle: this.defaultTransactionPrefix,
        },
      })
        .then(({ data }) => {
          this.$store.commit('updateBillingPayee', data);
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.error = data.title;
        })
        .finally(() => {
          this.$emit('update:pending', false);
        });
    },
    update() {
      this.updateBankAccount({
        data: {
          accountNumber: this.selected.accountNumber,
          name: this.accountName,
          isDefault: this.isDefault,
          isVisible: this.isVisible,
          paymentTitle: this.defaultTransactionPrefix,
        },
      })
        .then(({ data }) => {
          this.$store.commit('updateBillingPayee', data);
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(({ response: { data } }) => {
          this.error = data.title;
        })
        .finally(() => {
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.deleteBankAccount({
        params: {
          accountNumber: this.selected.accountNumber,
        },
      })
        .then(({ data }) => {
          this.$store.commit('updateBillingPayee', data);
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .finally(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.selected) {
      this.accNumberDisabled = true;
      this.accountNumber = accountNumber(this.selected.accountNumber.replace(/\s/g, ''));

      this.accountName = this.selected.name;
      this.defaultTransactionPrefix = this.selected.paymentTitle;
      this.isDefault = !!this.selected.isDefault;
      this.isVisible = !!this.selected.isVisible;
    }
    this.validate();
  },
};
</script>

<style lang="scss" scoped>

</style>
