<template>
  <div>
    <div class="position-relative">
      <div class="page-title-wrapper">
        <div
          class="page-title active"
        >
          {{ $t('menu.settings') }}
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="row no-gutters flex-column pr-3 justify-content-start mx-0 pt-1">
        <div
          v-for="r in menuItems"
          :key="r.route"
          class="col-12 col-sm-6 col-md-auto page-route mb-2 hover-underline"
          :class="r.route === selected ? 'active' : ''"
          @click.prevent="select(r)"
        >
          <i :class="r.icon" />
          <span class="text">
            {{ r.text }}
          </span>
        </div>
      </div>

      <div
        ref="content"
        class="flex-grow-1"
        :style="{
          height: `calc(100vh - ${topMargin}px)`,
          overflow: 'auto',
          'margin-top': '-6px',
        }"
      >
        <RouterView
          v-if="payee"
          @page="selected = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    selected: null,
    topMargin: 200,
  }),
  computed: {
    ...mapGetters(['payeeId', 'payee', 'tenantId']),
    menuItems() {
      return [
        {
          route: 'payers',
          text: this.$t('menu.payers'),
        },
        {
          route: 'bills',
          text: this.$t('menu.bills'),
        },
        {
          route: 'payments',
          text: this.$t('menu.payments'),
        },
        {
          route: 'accounting',
          text: this.$t('menu.accounting'),
        },
        {
          route: 'notifications',
          text: this.$t('settings.notifications'),
        },
        {
          route: 'shop',
          text: this.$t('menu.shop'),
        },
        {
          route: 'forms',
          text: this.$t('settings.forms.forms'),
        },
        // {
        //   route: 'currencies',
        //   text: this.$t('settings.currencies.currencies'),
        // },
        {
          route: 'access',
          text: this.$t('settings.access'),
        },
        {
          route: 'info',
          text: this.$t('company.contactInfo'),
        },
      ];
    },
  },
  methods: {
    select(p) {
      this.selected = p.route;
      const { payeeId } = this;
      this.$router.push(`/payee/${payeeId}/settings/${p.route}`);
    },
    calculateMarginTop() {
      if (!this.$refs.content) return;
      this.topMargin = this.$refs.content.getBoundingClientRect().top;
    },
  },
  created() {
    this.$emit('page', 'settings');
  },
  mounted() {
    this.calculateMarginTop();
  },
};
</script>

<style lang="scss" scoped>

  .tab {
    font-size: 12px;
  }

  .settings-content {
    height: calc(100vh - 200px);
  }

  .nav-pills {
    margin-top: 10px;
    padding: 20px 0 30px;
    border-right: 1px solid $blue;

    .nav-link {
      cursor: pointer;
      color: inherit;
      margin-top: 10px;
      font-size: 14px;
      text-align: right;
      border-radius: 0;
      font-weight: 500;
    }

    .nav-link:hover {
      color: $blue;
    }
    .nav-link.active {
      color: white;
    }
  }
</style>
