<template>
  <div>
    <div class="d-flex justify-content-between">
      <PayerHeader
        v-bind="payer"
        class="px-2"
      />
      <BillingScopeDropdown
        :selected.sync="billingScope"
        single
      />
    </div>
    <hr>
    <div
      class="d-flex justify-content-between align-items-center"
    >
      <h5 class="payer-section mb-0">
        {{ $t('bill.changeHistory') }}
      </h5>
      <div @click="$emit('close')">
        <i class="fas fa-times secondary-icon" />
      </div>
    </div>
    <hr class="mt-2">
    <div class="change-log">
      <div
        v-for="l in logWithProperty"
        :key="`${l.timestamp}_${l.propertyCode}`"
        class="d-flex align-items-center log-row"
      >
        <div
          style="width: 150px"
          class="text-secondary"
        >
          {{ formatDate(l.timestamp) }}
        </div>
        <div
          style="width: 120px"
          class="d-flex align-items-center"
        >
          <i
            :class="propertyIcon(l.property.type)"
            class="pr-1 property-icon"
          />
          {{ l.property.name }}
        </div>
        <div class="d-flex align-items-center flex-grow-1">
          <div class="value-box">
            {{ l.valueBefore }}
          </div>
          <div class="px-2">
            <i class="far fa-arrow-right" />
          </div>
          <div class="value-box">
            {{ l.valueAfter }}
          </div>
        </div>
        <div
          v-tippy
          :content="l.user.email"
          class="text-secondary ml-2"
        >
          <i class="fa-solid fa-user" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import BillingScopeDropdown from '../billingScopes/BillingScopeDropdown';
import PayerHeader from './PayerHeader';

export default {
  props: {
    payer: Object,
  },
  data: () => ({
    billingScope: null,
    response: {
      log: [],
      users: [],
    },
  }),
  components: {
    PayerHeader,
    BillingScopeDropdown,
  },
  computed: {
    ...mapGetters([
      'properties',
      'payersPropertyTypes',
    ]),
    logWithProperty() {
      return this.response.log.map((x) => ({
        ...x,
        user: this.response.users.find((u) => u.id === x.requesterId),
        property: this.properties.find((p) => p.code === x.propertyCode),
      }));
    },
  },
  watch: {
    billingScope() {
      this.log = [];
      this.request();
    },
  },
  methods: {
    ...mapActions(['getPayerPropertiesChangeLog']),
    formatDate(date) {
      return moment(date).format('HH:mm DD MMM YYYY');
    },
    propertyIcon(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
    request() {
      this.getPayerPropertiesChangeLog({
        params: {
          payerId: this.payer.id,
          query: {
            from: this.billingScope.from,
            to: this.billingScope.to,
          },
        },
      })
        .then(({ data }) => {
          this.response = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .payer-section {
    font-size: 16px;
    font-weight: 600 !important;
  }

  .property-icon {
    font-size: 11px;
  }

  .value-box {
    background-color: rgba($blue, 0.05);
    border-radius: 4px;
    padding: 1px 8px;
  }

  .log-row {
    font-size: 12px;
    padding: 5px 10px;

    &:hover {
      background-color: #fafafa;
    }
  }

  .change-log {
    border: 1px solid #eee;
  }
</style>
