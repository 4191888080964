var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"d-flex justify-content-between navbar"},[_vm._m(0),_c('div',[_c('button',{staticClass:"btn btn-primary"},[_c('a',{staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":"/"}},[_c('i',{staticClass:"fas fa-sign-in-alt pr-2"}),_vm._v(" "+_vm._s(_vm.$t('login.login'))+" ")])])])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('SmoothReflow',[(!_vm.pending && !_vm.error && _vm.groups.length > 0 && !_vm.messageScreen)?_c('div',[_c('div',{staticClass:"row justify-content-end no-gutters align-items-center mb-3",staticStyle:{"margin-left":"auto","margin-right":"0"}},[_c('div',{staticClass:"setting-description font-weight-bold d-flex flex-wrap",staticStyle:{"font-size":"14px"}},[_c('div',{staticClass:"d-flex col-12 col-md-12"},[_c('div',[_c('div',{staticClass:"info-wrapper d-flex align-items-center justify-content-center"},[_c('i',{staticClass:"fas fa-info"})])]),_c('div',{staticClass:"text-left",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('paymentStatus.reportText'))+" ")])])])])]):_vm._e()])],1),(!_vm.pending && !_vm.error && !_vm.messageScreen)?_c('div',[_vm._l((_vm.groups),function(g){return _c('div',{key:g.key},[_c('BasketGroup',_vm._b({attrs:{"payee-setup":_vm.payers[0],"toggable":"","payers":_vm.payers,"notify-pay":{
            id: _vm.id,
            date: _vm.date,
            hash: _vm.hash
          }}},'BasketGroup',g,false))],1)}),(_vm.bills.length === 0)?_c('div',{staticClass:"text-secondary text-center"},[_vm._m(1),_vm._v(" "+_vm._s(_vm.$t('bill.noneToPay'))+" ")]):_vm._e()],2):_vm._e(),(!_vm.pending && !_vm.error && _vm.messageScreen)?_c('div',[_c('MessageForm',{attrs:{"id":_vm.id,"date":_vm.date,"hash":_vm.hash},on:{"close":function($event){_vm.messageScreen = false}}})],1):_vm._e(),(_vm.pending)?_c('div',[_c('Loader',{attrs:{"size":"25px"}})],1):_vm._e(),(_vm.error)?_c('div',{staticClass:"text-center error-info",staticStyle:{"max-width":"400px","margin":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('paymentStatus.unableToGetData'))+" ")]):_vm._e(),(_vm.bankAccountSelectError)?_c('div',{staticClass:"text-center error-info",staticStyle:{"max-width":"400px","margin":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('paymentStatus.selectAccountError'))+" ")]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/p_blue.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"25px"}},[_c('i',{staticClass:"fa-regular fa-cart-circle-check"})])
}]

export { render, staticRenderFns }