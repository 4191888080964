<template>
  <div v-if="Object.keys(itemCategories).length === 0">
    {{ $t('client.clickToPay') }}
  </div>
  <div
    v-else
    class="p-2"
  >
    <div
      v-if="basketSummary.bills"
      class="d-flex justify-content-between"
    >
      <div class="text-secondary pr-2 left-column">
        {{ $t('menu.bills') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        {{ formatCurrency(basketSummary.bills, currency) }}
      </div>
    </div>
    <div v-if="!preview">
      <Loader />
    </div>
    <div
      v-if="preview && preview.ordered"
      class="d-flex order-preview-row"
    >
      <div class="name left-column">
        {{ $t('client.shop.ordered') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        {{ formatCurrency(preview.ordered, currency) }}
      </div>
    </div>
    <div
      v-if="preview && preview.cancelled !== 0"
      class="d-flex order-preview-row"
    >
      <div class="name left-column">
        {{ $t('client.shop.cancelled') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        -{{ formatCurrency(preview.cancelled, currency) }}
      </div>
    </div>
    <div
      v-if="preview && preview.otherBills !== 0"
      class="d-flex order-preview-row preview-detail"
    >
      <div class="name name-small left-column">
        {{ $t('shop.otherBills') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        {{ formatCurrency(preview.otherBills, currency) }}
      </div>
    </div>
    <div
      v-if="preview && preview.fromOverpayment > 0"
      class="d-flex order-preview-row preview-detail"
    >
      <div class="name name-small left-column">
        {{ $t('client.shop.fromOverpayment') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        -{{ formatCurrency(preview.fromOverpayment, currency) }}
      </div>
    </div>
    <div
      v-if="preview && preview.totalCommission > 0"
      class="d-flex order-preview-row preview-detail"
    >
      <div class="name left-column">
        {{ $t('client.commission') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        {{ formatCurrency(preview.totalCommission, currency) }}
      </div>
    </div>

    <hr class="my-2">
    <div
      class="d-flex order-preview-row"
    >
      <div class="name left-column">
        {{ $t('bill.toPay') }}:
      </div>
      <div class="money-font text-nowrap right-column">
        {{ formatCurrency(toPay, currency) }}
      </div>
    </div>

    <template v-if="singleGroup">
      <hr class="mt-2">
      <button
        v-if="!orderDetails"
        class="btn btn-primary"
        :disabled="orderPending"
        style="width: 220px"
        @click="orderProducts"
      >
        {{ cancelledItems.length > 0 ? $t('shop.confirmOrder') : $t('shop.orderAndPay') }}
      </button>

      <div
        v-if="orderError"
        class="text-danger small"
      >
        {{ $t('shop.unableToCreateOrder') }}
      </div>
    </template>
  </div>
</template>

<script>
import Bowser from 'bowser';
import moment from 'moment';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    list: Array,
    payeeId: String,
    singleGroup: Boolean,
  },
  data: () => ({
    orderDetails: null,
    orderPending: false,
    orderError: false,
    preview: null,
  }),
  computed: {
    ...mapGetters([
      'userPayers',
      'formatCurrency',
    ]),
    ...mapState({
      comments: (state) => state.userBills.comments,
    }),
    currency() {
      return this.list[0].currency;
    },
    itemCategories() {
      return this.list.reduce((acc, curr) => {
        if (curr.billId) return acc;
        if (acc[curr.categoryId]) {
          acc[curr.categoryId] += curr.value;
        } else {
          acc[curr.categoryId] = curr.value;
        }
        return acc;
      }, {});
    },
    billsToPay() {
      return this.list.filter((x) => x.billId).reduce((acc, curr) => acc + curr.value, 0);
    },
    ordersByCategory() {
      return Object.keys(this.itemCategories).reduce((acc, curr) => {
        const payee = this.userPayers
          .find((y) => y.payeeId === this.payeeId);

        if (!payee) {
          return acc;
        }

        const categoriesSum = this.userPayers
          .filter((y) => y.payeeId === this.payeeId)
          .flatMap((y) => y.categories)
          .filter((y) => y.categoryId === curr)
          .reduce((a, c) => a + c.balance, 0);

        const value = this.itemCategories[curr];

        acc[curr] = {
          value,
          fromOverpayment: Math.min(Math.max(0, categoriesSum), value),
        };

        return acc;
      }, {});
    },
    basketSummary() {
      return this.list.reduce((acc, curr) => {
        if (curr.billId) {
          acc.bills += curr.value;
        }

        acc.currency = curr.currency;
        return acc;
      }, {
        bills: 0, currency: '',
      });
    },
    toPay() {
      if (!this.preview) return this.billsToPay;
      const shopSum = this.preview.ordered
        + this.preview.otherBills
        - this.preview.cancelled
        - this.preview.fromOverpayment
        + this.preview.totalCommission;

      return Math.max(0, shopSum) + this.billsToPay;
    },
    cancelledItems() {
      return this.list
        .filter((x) => !x.billId)
        .filter((x) => x.isReturned || x.value < 0)
        .flatMap((x) => x.days.map((d) => ({
          availabilityId: x.availabilityId,
          payerId: x.payerId,
          day: d.day,
          count: Math.abs(d.count),
          comment: '',
        })));
    },
    addedItems() {
      return this.list
        .filter((x) => !x.billId)
        .filter((x) => !x.isReturned && x.value > 0)
        .flatMap((x) => x.days.map((d) => ({
          availabilityId: x.availabilityId,
          payerId: x.payerId,
          day: d.day,
          count: d.count,
          comment: this.comments[d.day] || '',
        })));
    },
  },
  watch: {
    toPay(v) {
      this.$emit('topay', v);
    },
    list: {
      deep: true,
      handler() {
        this.preview = null;
        this.createPreview();
      },
    },
  },
  methods: {
    ...mapActions([
      'getCreateOrderPreview',
      'createOrder',
      'paymentInit',
    ]),
    createPreview() {
      if (this.addedItems.length === 0 && this.cancelledItems.length === 0) {
        this.preview = null;
        return;
      }

      this.getCreateOrderPreview({
        params: {
          query: {
            payeeId: this.payeeId,
          },
        },
        data: {
          addedItems: this.addedItems,
          cancelledItems: this.cancelledItems,
        },
      })
        .then(({ data }) => {
          this.preview = data;
        });
    },
    pay(billsToPay) {
      this.paymentInit({
        data: {
          payeeId: this.payeeId,
          basket: billsToPay.map((x) => ({
            payerId: x.payerId,
            billId: x.billId,
          })),
        },
      })
        .then(({ data }) => {
          window.open(data.url, '_blank').focus();
        });
    },
    orderProducts() {
      this.orderPending = true;

      this.createOrder({
        params: {
          query: {
            payeeId: this.payeeId,
          },
        },
        data: {
          isApp: false,
          origin: JSON.stringify(Bowser.parse(window.navigator.userAgent)),
          version: process.env.VUE_APP_VERSION || '?',
          addedItems: this.addedItems,
          cancelledItems: this.cancelledItems,
        },
      })
        .then(({ data }) => {
          const autoPay = moment(data.paymentDeadline).isBefore(moment().add(1, 'hour'));
          this.$root.$emit('order-created');
          setTimeout(() => {
            this.$root.$emit('order-created');
          }, 2000);
          this.$store.commit('clearComments');

          if (this.addedItems.length > 0 && autoPay) {
            this.pay(data.bills);

            data.bills.forEach((b) => {
              this.$store.commit('toggleBillBasket', {
                payerId: b.payerId,
                billId: b.billId,
                payeeId: this.payeeId,
                gatewayId: b.gatewayId,
                accountNumber: b.accountNumber,
                value: (b.amount - b.paid),
                title: b.title,
                currency: b.currency,
              });
            });
          }

          this.list
            .filter((x) => !x.billId)
            .flatMap((x) => x.days.map((d) => ({ ...x, ...d })))
            .forEach((x) => {
              this.$store.commit('removeItemBasket', {
                payerId: x.payerId,
                availabilityId: x.availabilityId,
                day: x.day,
                timeFrame: x.timeFrame,
                isReturned: x.isReturned,
                isPaid: x.isPaid,
              });
            });
        })
        .catch(() => {
          this.orderError = true;
        })
        .finally(() => {
          this.orderPending = false;
        });
    },
  },
  created() {
    this.$emit('topay', this.toPay);
    this.createPreview();
  },
};
</script>

<style lang="scss" scoped>
.left-column {
  text-align: left;
}

.order-preview-row {
  align-items: center;
  justify-content: space-between;
  line-height: 1.1;
  padding-top: 5px;
  padding-bottom: 5px;

  .name {
    padding-right: 15px;
  }

}
.preview-detail {
  font-size: 0.75rem;
  color: $gray;
}

.right-column {
  width: 100px;
  text-align: right;
}
</style>
