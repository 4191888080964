<template>
  <BFormSelect
    v-if="sortedAccounts.length > 0"
    :value="bankAccountId"
    :state="validation"
    :options="sortedAccounts"
    style="font-size: 13px; max-width: 400px"
    @input="$emit('update:bankAccountId', $event)"
  />
  <div
    v-else
    class="d-flex align-items-center"
  >
    <TooltipHelp class="pl-2">
      <template #trigger>
        <div class="text-danger small warning">
          <i class="fas fa-exclamation-triangle pr-1" />
          {{ $t('settings.bankAccounts.noValidatedAccount') }}
        </div>
      </template>

      <div v-html="$t('settings.bankAccounts.noValidatedAccountInfo')" />
    </TooltipHelp>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    bankAccountId: String,
    findDefaultBankAccount: Boolean,
    validation: Boolean,
  },
  computed: {
    ...mapGetters([
      'properties',
      'payee',
    ]),
    personalBankAccounts() {
      return this.properties
        .filter((x) => x.type === 'BankAccount')
        .map((x) => {
          const account = this.payee.bankAccounts
            .find((e) => !e.isRejected && e.accountNumber.toString() === (x.format || '').toString());

          if (!account) { return null; }

          return {
            name: account.name,
            id: account.accountNumber,
            number: account.accountNumber,
            isVerified: account.isVerified,
            propertyId: x.code,
            isStatic: false,
          };
        })
        .filter((x) => x);
    },
    staticBankAccounts() {
      return this.payee.bankAccounts
        .filter((e) => !e.isRejected && !e.accountNumber.includes('_'))
        .map((x) => ({
          id: x.accountNumber,
          isVerified: x.isVerified,
          isStatic: true,
          name: `${x.name}: ${x.accountNumber}`,
          isDefault: x.isDefault,
        }));
    },
    sortedAccounts() {
      return this.staticBankAccounts
        .concat(this.personalBankAccounts)
        .map((x) => ({
          ...x,
          text: x.name,
          value: x.id,
        }))
        .concat([{
          text: '-',
          value: '',
          isStatic: true,
        }]);
    },
  },
  watch: {
    bankAccountId(id) {
      if (!id) {
        this.$emit('select', null);
      }
      const accountData = this.sortedAccounts.find((x) => x.value.toString() === this.bankAccountId.toString());
      this.$emit('select', accountData);
    },
  },
  created() {
    if (this.findDefaultBankAccount) {
      if (!this.bankAccountId && this.staticBankAccounts.length > 0) {
        const defaultAccount = this.staticBankAccounts.find((x) => x.isDefault);
        const accountId = (defaultAccount || this.staticBankAccounts[0]).id.toString();
        this.$emit('update:bankAccountId', accountId);
      }
    }
  },
};
</script>
