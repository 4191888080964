<template>
  <div
    class="px-2"
    style="min-height: 100%; max-width: 600px"
  >
    <div class="form-group pb-2">
      <label for="paymentTitle">{{ $t('payment.title') }}</label>
      <input
        id="paymentTitle"
        v-model="title"
        class="form-control"
        data-test="payment-title"
        :placeholder="$t('payment.title')"
      >
    </div>
    <div class="row no-gutters mx-0 w-100 pb-2">
      <div class="col-6">
        <div class="form-group mr-2">
          <label for="paymentTitle">{{ $t('bill.code') }}</label>
          <input
            v-model="code"
            class="form-control"
            :placeholder="$t('bill.code')"
          >
        </div>
      </div>
      <div
        v-if="payee.categories.length > 1"
        class="col-6"
      >
        <div class="ml-2">
          <label>{{ $t('bill.category') }}</label>
          <CategoryDropdown
            :selected.sync="category"
          />
        </div>
      </div>
    </div>
    <div class="form-group pb-3">
      <label for="paymentDesc">{{ $t('payment.description') }}</label>
      <textarea
        id="paymentDesc"
        v-model="description"
        class="form-control"
        data-test="payment-description"
        :placeholder="$t('payment.description')"
      />
    </div>
    <div class="row no-gutters">
      <div
        v-if="type !== 'recurring'"
        class="col-6 pb-3"
      >
        <div class="mr-2">
          <label>{{ $t('payment.startDate') }}</label>
          <CtkDateTimePicker
            id="repeat-from"
            v-model="from"
            format="YYYY-MM-DD"
            formatted="ddd D MMM YYYY"
            :label="$t('payment.repeatFrom')"
            :locale="locale"
            :only-date="true"
            data-test="bills-payment-from"
          />
        </div>
      </div>
      <div class="col-6 pb-3">
        <div class="ml-2">
          <label>{{ $t('payment.daysToPay') }}</label>
          <input
            v-model="paymentTime"
            type="number"
            class="form-control"
            value="14"
            data-test="days-for-payment"
          >
        </div>
      </div>
    </div>

    <hr v-if="!hideType">

    <div
      v-if="!hideType"
      class="d-flex pt-3"
    >
      <div
        style="width: 200px"
        class="mx-2"
      >
        <button
          class="btn btn-block"
          :class="!isPlan ? 'btn-primary' : 'btn-outline-primary'"
          @click="isPlan = false"
        >
          <i class="icon-bill pr-1" />
          {{ $t('payment.single') }}
        </button>
      </div>
      <div
        style="width: 200px"
        class="mx-2"
      >
        <button
          class="btn btn-block"
          :class="isPlan ? 'btn-primary' : 'btn-outline-primary'"
          @click="isPlan = true; scrollToRecurring()"
        >
          <i class="fas fa-calendar pr-1" />
          {{ $t('payment.recurring') }}
        </button>
      </div>
    </div>

    <div id="recurring-settings">
      <div
        v-if="isPlan"
      >
        <div class="form-group mb-2 mt-4">
          <div class="row no-gutters mx-0">
            <div class="col-6 pr-1">
              <label for="recurringEvery">{{ $t('payment.repeatEvery') }}</label>
              <input
                id="recurringEveryNum"
                v-model="repeatNumber"
                required
                type="number"
                min="0"
                max="30"
                class="form-control"
                data-test="recurring-repeat-every-number"
              >
            </div>
            <div class="col-6 pl-1">
              <label class="text-white">.&zwnj;</label>
              <select
                v-model="repeatType"
                class="custom-select"
                data-test="recurring-repeat-every-type"
              >
                <option value="Month">
                  {{ $t(`time.months.${getPluralType(repeatNumber)}`) }}
                </option>
                <option value="Week">
                  {{ $t(`time.weeks.${getPluralType(repeatNumber)}`) }}
                </option>
                <option value="Day">
                  {{ repeatNumber > 1 ? $t('time.days') : $t('time.day') }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row no-gutters mx-0">
          <div class="col-12 form-group mb-2 mt-4">
            <div class="row justify-content-center">
              <div class="col-6">
                <label>{{ $t('payment.repeatFrom') }}</label>
                <CtkDateTimePicker
                  id="repeat-from"
                  v-model="from"
                  format="YYYY-MM-DD"
                  formatted="ddd D MMM YYYY"
                  :label="$t('payment.repeatFrom')"
                  :locale="locale"
                  :only-date="true"
                  data-test="bills-payment-from"
                />
              </div>
              <div class="col-6">
                <label>{{ $t('payment.repeatTo') }}</label>
                <CtkDateTimePicker
                  id="repeat-to"
                  v-model="to"
                  format="YYYY-MM-DD"
                  formatted="ddd D MMM YYYY"
                  :label="$t('payment.repeatTo')"
                  :locale="locale"
                  :only-date="true"
                  data-test="bills-payment-to"
                />
              </div>
            </div>
            <div class="recurring-description">
              <i class="ion ion-ios-information-circle-outline" />
              <RepeatInfo
                :repeat-type="repeatType"
                :repeat-number="repeatNumber"
                :from="from"
              />
            </div>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-6 pr-1">
            <label for="recurringEvery">{{ $t('billingPlan.generateDaysBefore') }}</label>
            <input
              v-model="earlyExecutionDays"
              required
              type="number"
              min="0"
              max="60"
              class="form-control"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import CtkDateTimePicker from 'vue-ctk-date-time-picker';
import { mapGetters } from 'vuex';
import CategoryDropdown from './CategoryDropdown';
import RepeatInfo from './RepeatInfo';

export default {
  props: {
    hideType: Boolean,
    billIsPlan: Boolean,
    billTitle: String,
    billCode: String,
    billDescription: String,
    billPaymentTime: [String, Number],
    billRepeatNumber: [String, Number],
    billRepeatType: String,
    billFrom: String,
    billTo: String,
    billCategory: String,
    billEarlyExecutionDays: [String, Number],
  },
  data: () => ({
    title: '',
    description: '',
    code: '',
    isPlan: false,
    repeatType: 'Month',
    paymentTime: '14',
    repeatNumber: '1',
    category: '',
    earlyExecutionDays: 1,
    from: moment.utc().format('YYYY-MM-DD'),
    to: null,
  }),
  components: {
    RepeatInfo,
    CategoryDropdown,
    CtkDateTimePicker,
  },
  computed: {
    ...mapGetters(['locale', 'payee']),
    defaultCategory() {
      return this.payee?.categories.find((x) => x.isDefault);
    },
  },
  watch: {
    title(t) {
      this.$emit('update:billTitle', t);
    },
    code(t) {
      this.$emit('update:billCode', t);
    },
    description(d) {
      this.$emit('update:billDescription', d);
    },
    repeatNumber(d) {
      this.$emit('update:billRepeatNumber', d);
    },
    repeatType(d) {
      this.$emit('update:billRepeatType', d);
    },
    paymentTime(d) {
      this.$emit('update:billPaymentTime', d);
    },
    from(d) {
      this.$emit('update:billFrom', d);
    },
    to(d) {
      this.$emit('update:billTo', d);
    },
    category(d) {
      this.$emit('update:billCategory', d);
    },
    isPlan(d) {
      this.$emit('update:billIsPlan', d);
    },
    earlyExecutionDays(d) {
      this.$emit('update:billEarlyExecutionDays', d);
    },
  },
  methods: {
    getPluralType(m) {
      const n = parseInt(m, 10);
      if (Number.isNaN(n)) return 'zero';
      if (n === 0) return 'zero';
      if (n === 1) return 'one';
      if (n < 5) return 'few';
      return 'many';
    },
    scrollToRecurring() {
      this.$scrollTo('#recurring-settings', 300, {
        container: '#title-and-description',
        offset: 0,
      });
    },
  },
  created() {
    this.type = this.billType;
    this.category = this.billCategory;
    this.description = this.billDescription;
    this.title = this.billTitle;
    this.code = this.billCode;
    this.repeatNumber = this.billRepeatNumber;
    this.repeatType = this.billRepeatType;
    this.paymentTime = this.billPaymentTime;
    this.isPlan = this.billIsPlan;
    this.from = this.billFrom;
    this.to = this.billTo;
    this.earlyExecutionDays = this.billEarlyExecutionDays;

    if (!this.category && this.defaultCategory) {
      this.category = this.defaultCategory.id;
    }
  },
};
</script>

<style lang="scss" scoped>
  .recurring-description {
    color: #858383;
    font-size: 13px;
    padding-top: 5px;
  }
</style>
