<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <CategoryInfo
          v-if="selected"
          :category-id="selected"
          style="font-size: 1rem"
        />
        <div v-else>
          ?
        </div>
      </div>
    </template>

    <div style="min-width: 180px">
      <div
        v-for="(s, i) in categoryOptions"
        :key="s.key"
        class="sort-option text-left d-flex"
        :class="{
          first: i === 0,
          active: selected === s.key
        }"
        @click="$emit('update:selected', s.key); $refs.popover.tippy().hide()"
      >
        <div class="flex-grow-1">
          <CategoryInfo
            class="category-row"
            :category-id="s.key"
          />
        </div>
      </div>
    </div>
  </Tippy>
</template>

<script>
import { mapGetters } from 'vuex';
import CategoryInfo from '@/components/CategoryInfo';

export default {
  props: {
    selected: String,
  },
  components: {
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['payee']),
    categoryOptions() {
      return this.payee.categories
        .filter((x) => x.isActive)
        .map((x) => ({
          key: x.id,
          text: x.name,
        }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../payers/style.scss";

 .sort-option-selected  {
    background-color: #fff;
    border: 1px solid #ced4da;
    display: flex;
    align-items: center;
    min-height: 38px;
    border-radius: 1rem;
  }

  .category-row::v-deep .color-box {
    margin-right: 10px;
  }

  .sort-option.active {
    font-weight: 500;
    color: inherit;
  }
</style>
