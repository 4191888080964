<template>
  <div
    v-if="pendingPayers"
    class="text-center mt-3"
  >
    <Loader />
  </div>
  <div v-else-if="userPayers.filter(x => !x.isInvite).length === 0">
    <div
      class="mt-4"
    >
      <div>
        <div
          class="font-weight-bold h5"
          v-html="$t('client.noConnectedClients')"
        />
        <div v-html="$t('client.noConnectedClientsShopInfo')" />
      </div>
    </div>
  </div>
  <div
    v-else-if="availableOffer.length === 0"
    class="shop-baner"
  >
    <i class="far fa-basket-shopping" />
    <div v-html="$t('shop.thisIsShop')" />
  </div>
  <div
    v-else
    class="mt-4"
  >
    <div
      v-if="selected"
    >
      <div
        class="d-flex align-items-center"
      >
        <i
          class="fas fa-chevron-left mr-2 secondary-icon"
          @click="select(null)"
        />
        <div class="flex-grow-1">
          <span class="font-weight-bold pr-2">
            {{ $t('shop.mealOffer') }}:
          </span>
          <span class="text-primary">
            {{ selected.payeeShortName }}
          </span>
          <span class="px-2">|</span>
          {{ selected.payerName }}
        </div>
      </div>
      <hr>
      <template v-if="selected.payerType === 'Person'">
        <div class="mb-3">
          <button
            class="btn btn-sm"
            :class="!showCalendar ? 'btn-secondary' : 'btn-outline-secondary'"
            style="width: 150px"
            @click.prevent="showCalendar = false"
          >
            <i class="fa-regular fa-table-list pr-1" />
            {{ $t('shop.list') }}
          </button>
          <button
            class="btn btn-sm ml-2"
            style="width: 150px"
            :class="showCalendar ? 'btn-secondary' : 'btn-outline-secondary'"
            @click.prevent="showCalendar = true"
          >
            <i class="far fa-calendar-days pr-1" />
            {{ $t('shop.calendar') }}
          </button>
        </div>

        <CalendarOffer
          v-if="showCalendar"
          v-bind="selected"
          :init-at="initAt"
        />
        <ShopSummaryPage
          v-else
          v-bind="selected"
          @calendar="initAt = $event; showCalendar = true"
        />
      </template>
      <template v-else>
        <CompanyCalendarOrder
          v-bind="selected"
        />
      </template>
    </div>
    <div v-else>
      <div

        class="font-weight-bold"
      >
        {{ $t('shop.mealOffer') }}:
      </div>
      <div
        class="mt-4 payer-box-container"
      >
        <div
          v-for="p in availableOffer"
          :key="p.payerId"
          class="payer-box d-flex align-items-center"
          :class="{
            selected: selected && selected.payerId == p.payerId
          }"
          @click="select(p.payerId)"
        >
          <div
            style="width: 70px"
            class="user-icon text-center"
          >
            <i
              v-if="p.payerType === 'Person'"
              class="far fa-user"
            />
            <i
              v-else
              class="far fa-building"
            />
          </div>
          <div class="flex-grow-1">
            <div class="payer-name">
              {{ p.payerName }}
            </div>
            <div class="payee-name">
              {{ p.payeeShortName }}
            </div>
          </div>
          <div class="orders-button pr-4">
            {{ $t('shop.order') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CalendarOffer from '../components/shop/CalendarOffer';
import ShopSummaryPage from '../components/shop/ShopSummaryPage';
import CompanyCalendarOrder from '../components/shop/company/CompanyCalendarOrder';

export default {
  props: {
    payerId: String,
  },
  data: () => ({
    offer: null,
    pendingPayers: false,
    summary: null,
    showCalendar: false,
    initAt: null,
  }),
  components: {
    CalendarOffer,
    ShopSummaryPage,
    CompanyCalendarOrder,
  },
  computed: {
    ...mapGetters(['userPayers', 'formatCurrency']),
    ...mapState({
      basket: (state) => state.userBills.basket,
    }),
    selected() {
      return this.availableOffer.find((x) => x.payerId === this.payerId);
    },
    toPay() {
      return Object.keys(this.basket)
        .filter((x) => x.startsWith('item'))
        .reduce((acc, key) => acc + this.basket[key].value, 0);
    },
    availableOffer() {
      if (this.pendingPayers) return [];
      return this.userPayers
        .filter((x) => x.shop && !x.isInvite)
        .sort((a, b) => a.payerName.localeCompare(b.payerName));
    },
  },
  watch: {
    availableOffer(list) {
      if (list && list.length === 1) {
        this.$router.replace(`/payer/shop/${list[0].payerId}`);
      }
    },
    showCalendar(v) {
      if (v) {
        this.$router.replace({ query: { calendar: true, at: this.initAt } });
      } else {
        this.$router.replace({ query: {} });
      }
    },
  },
  methods: {
    ...mapActions([
      'getUserShop',
      'getUserPayers',
    ]),
    select(payerId) {
      if (payerId) {
        this.$router.push(`/payer/shop/${payerId}`);
      } else {
        this.$router.push('/payer/shop');
      }
    },
  },
  created() {
    this.$emit('page', 'shop');

    if (this.$route.query.calendar) {
      this.initAt = this.$route.query.at;
      this.showCalendar = true;
    }

    if (!this.userPayers || this.userPayers.length === 0) {
      this.pendingPayers = true;
      this.getUserPayers()
        .finally(() => {
          this.pendingPayers = false;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
  .shop-baner {
    padding-top: 50px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    color: #aaa;

    i {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }

  .orders-button {
    text-align: center;
    font-weight: 400;
    color: #aaa;
    margin-top: 4px;
    padding: 5px 0;
    // border-top: 1px solid #eee;
  }

  .payer-box-container {
    height: 150px;
    font-size: 14px;
    width: 100%;
  }

 .payer-box {
    width: 100%;
    max-width: 800px;
    height: 120px;
    margin-top: 20px;
    box-shadow: 0px 3px 10px rgb(221, 227, 235);
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: box-shadow 300ms, border 300ms;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #aaa;

      .orders-button {
        color: $blue;
      }
    }

    &.selected {
      border: 2px solid $blue;
    }

    &.invite {
      border: 2px solid $blue;
    }

    .payer-name {
      font-weight: 500;
    }

    .user-icon {
      color: #aaa;
      font-size: 22px;
    }

    .payee-name {
      font-size: 12px;
    }

    .invite-accept {
      box-shadow: none;
      margin-top: 8px;
    }
 }
</style>
