<template>
  <div
    class="d-flex flex-column"
    style="min-height: 100%;"
  >
    <div>
      <HomePayers
        v-if="!pendingPayers"
        @refresh="request"
      />
      <div
        v-else
        class="mt-4"
      >
        <Loader />
      </div>

      <hr class="mb-0">
    </div>

    <BModal
      v-if="userPayers"
      v-model="acceptModal"
      hide-footer
      hide-header
    >
      <div
        class="font-weight-bold"
        style="font-size: 20px"
      >
        {{ $t('client.pendingInvitation') }}:
      </div>
      <div>
        {{ $t('client.pendingInvitationInfo') }}:
      </div>
      <hr class="mb-2">
      <div
        v-for="p in userPayers.filter(x => x.isInvite)"
        :key="p.id"
        class="payer-box d-flex align-items-center my-2"
      >
        <div
          class="user-icon px-3"
        >
          <i class="far fa-user" />
        </div>
        <div>
          <div class="payer-name">
            {{ p.payerName }}
          </div>
          <div class="payee-name">
            {{ p.payeeShortName }}
          </div>
        </div>
      </div>

      <hr>
      <div class="d-flex">
        <div class="w-50 mx-2">
          <button
            class="btn btn-block btn-secondary"
            :disabled="groupAcceptPending"
            @click="acceptModal = false"
          >
            <i class="fas fa-xmark pr-2" />
            <span class="pr-2">
              {{ $t('general.cancel') }}
            </span>
          </button>
        </div>
        <div class="w-50 mx-2">
          <button
            class="btn btn-block btn-primary"
            :disabled="groupAcceptPending"
            @click="acceptAll"
          >
            <i class="fas fa-user-plus pr-2" />
            <span class="pr-2">
              {{ $t('general.add') }}
            </span>
          </button>
        </div>
      </div>
    </BModal>

    <div
      v-if="havePayers"
      style="max-width: 1000px"
    >
      <template v-if="overpayments.length > 0">
        <div
          class="title mb-3"
        >
          {{ $t('payment.overpayment') }}
        </div>

        <div style="padding: 2px 5px">
          <Overpayment
            v-for="o in overpayments"
            :key="`${o.categoryId}_${o.payer.payerId}`"
            v-bind="o"
          />
        </div>
        <hr>
      </template>

      <template v-if="!paidBills">
        <div class="d-flex justify-content-between">
          <div
            v-if="userOutstanding.length > 0 || pendingBills"
            class="title mb-3"
          >
            {{ $t('client.bills') }}
          </div>
          <div
            class="user-link"
            @click="showPaid()"
          >
            {{ $t('client.seePaid') }}
          </div>
        </div>
        <div style="padding: 2px 5px">
          <Bill
            v-for="bill in userOutstanding"
            :key="`${bill.billId}_${bill.payerId}`"
            v-bind="bill"
            :payer="getPayer(bill.payerId)"
          />
          <Loader v-if="pendingBills" />
        </div>
        <div v-if="!pendingBills && userOutstanding.length === 0">
          <div
            class="text-secondary text-center mt-4"
          >
            <div style="font-size: 25px">
              <i class="fa-regular fa-cart-circle-check" />
            </div>
            {{ $t('bill.noneToPay') }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex justify-content-between">
          <div class="title mb-3">
            {{ $t('client.paid') }}
          </div>
          <div
            class="user-link"
            @click="paidBills = false"
          >
            {{ $t('client.seeUnpaid') }}
          </div>
        </div>
        <div style="padding: 2px 5px">
          <Bill
            v-for="bill in paid"
            :key="`${bill.billId}_${bill.payerId}`"
            v-bind="bill"
            :payer="getPayer(bill.payerId)"
          />
          <Loader v-if="pendingPaidBills" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Bill from '../components/Bill';
import HomePayers from '../components/HomePayers';
import Overpayment from '../components/Overpayment';

const dateOnlyFormat = 'YYYY-MM-DD';

export default {
  data: () => ({
    pendingBills: false,
    pendingPayers: false,
    pendingPaidBills: false,
    error: false,
    paidBills: false,
    paidFrom: null,
    paidTo: null,
    havePayers: false,
    groupAcceptPending: false,
    acceptModal: false,
    paid: [],
  }),
  components: {
    HomePayers,
    Overpayment,
    Bill,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'userPayers',
      'userOutstanding',
      'refreshUserPayers',
    ]),
    overpayments() {
      return this.userPayers
        .flatMap((x) => (x.categories || []).map((y) => ({ ...y, payer: x })))
        .filter((x) => x.balance > 0);
    },
  },
  methods: {
    ...mapActions([
      'getUserOutstandingBills',
      'getUserPaidBills',
      'acceptUserRoleInvite',
      'getUserPayers',
    ]),
    showPaid() {
      this.paidBills = true;
      this.paidFrom = moment().startOf('day').subtract(24, 'months').format(dateOnlyFormat);
      this.paidTo = moment().add(2, 'months')
        .endOf('month')
        .startOf('day')
        .format(dateOnlyFormat);

      this.requestPaid();
    },
    getPayer(payerId) {
      return this.userPayers.find((x) => x.payerId === payerId) ?? {};
    },
    requestPaid() {
      this.pendingPaidBills = true;
      this.getUserPaidBills({
        params: {
          query: {
            from: this.paidFrom,
            to: this.paidTo,
          },
        },
      })
        .then(({ data }) => {
          this.paid = data;
        })
        .finally(() => {
          this.pendingPaidBills = false;
        });
    },
    acceptAll() {
      this.groupAcceptPending = true;
      const promises = this.userPayers
        .filter((x) => x.isInvite)
        .map((x) => this.acceptUserRoleInvite({
          data: {
            payeeId: x.payeeId,
            payerId: x.payerId,
          },
        }));

      Promise.all(promises)
        .then(() => {
          this.acceptModal = false;
          this.request();
        })
        .finally(() => {
          this.groupAcceptPending = false;
        });
    },
    request() {
      this.pendingBills = true;
      this.pendingPayers = true;
      this.error = false;

      this.getUserPayers()
        .then(({ data }) => {
          if (data.length > 0) {
            this.havePayers = true;

            if (data.some((x) => x.isInvite)) {
              this.acceptModal = true;
            }
          }
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pendingPayers = false;
        });

      this.getUserOutstandingBills()
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.pendingBills = false;
        });
    },
  },
  created() {
    this.$emit('page', 'home');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  margin-top: 26px;
}

.payer-box {
  height: 100px;
  box-shadow: 0px 3px 10px rgb(221, 227, 235);
  border-radius: 10px;
  margin-right: 10px;
  padding: 15px;
  border: 2px solid $blue;

  .payer-name {
    font-weight: 500;
  }

  .user-icon {
    color: #aaa;
    font-size: 22px;
  }

  .payee-name {
    font-size: 12px;
  }

  .invite-accept {
    box-shadow: none;
    margin-top: 8px;
  }
 }

.user-link {
  margin-top: 28px;
  font-size: 12px;
  text-decoration: underline;
  color: $blue;
  cursor: pointer;

  &:hover {
    color: darken($blue, 10);
  }
}
</style>
