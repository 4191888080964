<template>
  <Tippy
    ref="popover"
    :arrow="true"
    trigger="click"
    interactive
    theme="light"
    placement="bottom"
    :max-width="500"
    @shown="refresh()"
  >
    <template #trigger>
      <div class="sort-option-selected">
        <div
          class="font-weight-bold text-secondary"
          style="font-size: 10px; min-width: 180px"
        >
          {{ $t('time.period') }}
        </div>
        <div
          style="font-size: 14px"
        >
          {{ selectTitle }}
        </div>
      </div>
    </template>

    <div
      style="width: 350px; margin: 10px 5px"
      class="position-relative"
    >
      <Calendar
        ref="calendar"
        :available-days="availableDays"
        :selected-days="[]"
        :day-status="dayStatus"
        :single-day="singleDay"
        :all-available="allAvailable"
        compressed
        @dates="$emit('update:dates', $event)"
        @highlighted="$emit('update:highlighted', $event)"
      />
    </div>
  </Tippy>
</template>

<script>
import Calendar from '@/modules/payer/components/shop/Calendar';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    highlighted: Array,
    availableDays: Array,
    dayStatus: Object,
    dates: Object,
    singleDay: Boolean,
    allAvailable: Boolean,
  },
  data: () => ({
    show: false,
  }),
  components: {
    Calendar,
  },
  computed: {
    ...mapGetters(['payee']),
    selectTitle() {
      if (!this.dates) return '';
      if (this.highlighted.length === 0) {
        if (this.singleDay) {
          return moment().format('DD MMMM');
        }
        return moment(this.dates.from).format('MMMM YYYY');
      }
      if (this.highlighted.length === 1) {
        return moment(this.highlighted[0], 'YYYY-MM-DD').format('DD MMMM');
      }

      const first = this.highlighted[0];
      const last = this.highlighted[this.highlighted.length - 1];

      return `${moment(first, 'YYYY-MM-DD').format('DD MMMM')} - ${moment(last, 'YYYY-MM-DD').format('DD MMMM')}`;
    },
    firstYear() {
      if (!this.payee) return this.selected;
      return moment.utc(this.payee.oldestBillingScope).year();
    },
    years() {
      let current = parseInt(moment().format('YYYY'), 10);
      const years = [];

      while (current >= this.firstYear) {
        years.push(current);
        current -= 1;
      }

      return years;
    },
  },
  watch: {
    selectTitle(title) {
      this.$emit('title', title);
    },
  },
  methods: {
    refresh() {
      this.$refs.calendar.calculateWrapper();
    },
  },
  created() {
    this.$emit('title', this.selectTitle);
  },
};
</script>

<style lang="scss" scoped>
 @import '@/components/payers/style.scss';
</style>
