<template>
  <div class="pb-3">
    <div
      class="mt-1 pb-3"
    >
      <div
        v-for="b in outstanding"
        :key="b.id"
        class="d-flex justify-content-between align-items-center settlement-row"
      >
        <i
          class="far fa-money-bills small pr-1"
          :style="{ color: getCategoryColor(b.categoryId) }"
        />
        <div class="flex-grow-1 pl-1">
          {{ b.title }}
        </div>
        <div
          class="small pr-2"
          :class="{ 'text-danger': settlement[b.billId] > (b.amount - b.paid) }"
        >
          {{ $t('bill.toPay') }}:
          <span class="money-font pl-1">
            {{ formatCurrency(b.amount - b.paid, b.currency) }}
          </span>
        </div>
        <div
          class="d-flex align-items-center"
          style="width: 220px"
        >
          <MoneyInput
            v-model="settlement[b.billId]"
            placeholder="0.00"
            class="font-money text-right"
            :highlighted="settlement[b.billId] > 0"
            size="sm"
            :currency="currency"
            @input="emit"
          />
          <div class="pl-2">
            <button
              class="btn btn-sm icon-btn"
              :class="settlement[b.billId] > 0 ? 'btn-secondary' : 'btn-primary'"
              @click="toggle(b)"
            >
              <i
                v-if="settlement[b.billId] > 0"
                class="fas fa-minus"
              />
              <i
                v-else
                class="fas fa-plus"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        v-for="cat in payeeCategories"
        :key="cat.id"
        class="d-flex justify-content-between align-items-center settlement-row"
      >
        <CategoryInfo
          :category-id="cat.id"
          style="font-size: 1rem"
        />
        <div
          class="d-flex align-items-center"
          style="width: 220px"
        >
          <MoneyInput
            v-model="settlement[cat.id]"
            placeholder="0.00"
            :highlighted="settlement[cat.id] > 0"
            class="font-money text-right"
            size="sm"
            :currency="currency"
            @input="emit"
          />
          <div class="pl-2">
            <button
              class="btn btn-primary btn-sm icon-btn"
              :class="settlement[cat.id] > 0 ? 'btn-secondary' : 'btn-primary'"
              @click="toggleCategory(cat.id)"
            >
              <i
                v-if="settlement[cat.id] > 0"
                class="fas fa-minus"
              />
              <i
                v-else
                class="fas fa-plus"
              />
            </button>
          </div>
        </div>
      </div>

      <div
        class="d-flex justify-content-between align-items-center settlement-row refund"
        :style="{
          'max-width': showRefund ? null : '300px'
        }"
        style="margin-right: 0; margin-left: auto;"
        @click="showRefund = true"
      >
        <i class="fa-regular fa-arrow-turn-down-left pr-1 small" />
        <div class="flex-grow-1 pl-2">
          {{ $t('payment.creditReturn') }}
        </div>
        <div
          v-if="showRefund"
          class="d-flex align-items-center"
          style="width: 220px"
        >
          <MoneyInput
            v-model="refund"
            placeholder="0.00"
            class="font-money text-right"
            :highlighted="refund > 0"
            size="sm"
            :currency="currency"
            @input="emit"
          />
          <div class="pl-2">
            <button
              class="btn btn-sm btn-primary icon-btn"
              @click.stop="toggleRefund"
            >
              <i
                v-if="refund > 0"
                class="fas fa-minus"
              />
              <i
                v-else
                class="fas fa-plus"
              />
            </button>
          </div>
          <div class="pl-2">
            <button
              class="btn btn-sm btn-secondary icon-btn"
              @click.stop="showRefund = false; refund = 0; emit()"
            >
              <i
                class="fas fa-times"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import MoneyInput from '@/components/utils/MoneyInput';
import colors from '@/utils/colors';
import { mapGetters } from 'vuex';

export default {
  props: {
    payer: Object,
    outstanding: Array,
    toSettle: Number,
    currency: String,
  },
  data: () => ({
    settlement: {},
    refund: 0,
    showRefund: false,
  }),
  components: {
    MoneyInput,
    CategoryInfo,
  },
  computed: {
    ...mapGetters(['payee', 'formatCurrency']),
    payeeCategories() {
      return (this.payee?.categories || [])
        .filter((x) => x.isActive || this.settlement[x.id] > 0);
    },
  },
  watch: {
    payer() {
      this.init();
    },
  },
  methods: {
    colors,
    toggleRefund() {
      if (this.refund > 0) {
        this.refund = 0;
      } else {
        this.refund = Math.max(0, this.toSettle);
      }

      this.emit();
    },
    toggle(bill) {
      const key = bill.billId;
      if (this.settlement[key] > 0) {
        this.$set(this.settlement, key, 0);
      } else {
        this.$set(this.settlement, key, Math.max(0, Math.min(this.toSettle, bill.amount - bill.paid)));
      }
      this.emit();
    },
    toggleCategory(categoryId) {
      if (this.settlement[categoryId] > 0) {
        this.$set(this.settlement, categoryId, 0);
      } else {
        this.$set(this.settlement, categoryId, Math.max(0, this.toSettle));
      }
      this.emit();
    },
    emit() {
      const bills = this.outstanding
        .map((x) => ({
          value: this.settlement[x.billId],
          payerId: this.payer.id,
          billId: x.billId,
        }))
        .filter((x) => x.value > 0);

      const overpayments = this.payee.categories
        .map((x) => ({
          value: this.settlement[x.id],
          payerId: this.payer.id,
          categoryId: x.id,
        }))
        .filter((x) => x.value > 0);

      const refunds = this.refund > 0 ? [{
        value: this.refund,
        payerId: this.payer.id,
      }] : [];

      this.$emit('settlement', {
        bills,
        overpayments,
        refunds,
      });
    },
    getCategoryColor(categoryId) {
      return colors(this.payee?.categories.find((x) => x.id === categoryId).colorCode);
    },
    init() {
      this.settlement = {};
      this.refund = 0;

      if (!this.payer.settlement) return;

      this.payer.settlement.bills.forEach((b) => {
        this.$set(this.settlement, b.billId, b.value);
      });
      this.payer.settlement.overpayments.forEach((b) => {
        this.$set(this.settlement, b.categoryId, b.value);
      });
      if (this.payer.settlement.refunds.length > 0) {
        this.showRefund = true;
        this.refund = this.payer.settlement.refunds[0].value;
      }
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.settlement-row {
  background-color: #fbfbfb;
  border-radius: 12px;
  padding: 5px 12px;
  margin-top: 5px;
  transition: opacity 200ms;
}

.refund {
  cursor: pointer;
  height: 41px;
  transition: max-width 400ms;

  &:hover {
    background-color: #eee;
  }
}
</style>
