<template>
  <div class="container-fluid">
    <div class="content">
      <div class="d-flex justify-content-between navbar">
        <div>
          <img
            src="@/assets/p_blue.png"
            class="logo"
          >
        </div>
        <div>
          <button class="btn btn-primary">
            <a
              href="/"
              style="text-decoration: none; color: white;"
            >
              <i class="fas fa-sign-in-alt pr-2" />
              {{ $t('login.login') }}
            </a>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <SmoothReflow>
          <div v-if="!pending && !error && groups.length > 0 && !messageScreen">
            <div
              class="row justify-content-end no-gutters align-items-center mb-3"
              style="margin-left: auto; margin-right: 0"
            >
              <div
                class="setting-description font-weight-bold d-flex flex-wrap"
                style="font-size: 14px"
              >
                <div class="d-flex col-12 col-md-12">
                  <div>
                    <div class="info-wrapper d-flex align-items-center justify-content-center">
                      <i class="fas fa-info" />
                    </div>
                  </div>
                  <div
                    class="text-left"
                    style="font-size: 13px"
                  >
                    {{ $t('paymentStatus.reportText') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SmoothReflow>
      </div>
      <div v-if="!pending && !error && !messageScreen">
        <div
          v-for="g in groups"
          :key="g.key"
        >
          <BasketGroup
            v-bind="g"
            :payee-setup="payers[0]"
            toggable
            :payers="payers"
            :notify-pay="{
              id,
              date,
              hash
            }"
          />
        </div>
        <div
          v-if="bills.length === 0"
          class="text-secondary text-center"
        >
          <div style="font-size: 25px">
            <i class="fa-regular fa-cart-circle-check" />
          </div>
          {{ $t('bill.noneToPay') }}
        </div>
      </div>
      <div v-if="!pending && !error && messageScreen">
        <MessageForm
          :id="id"
          :date="date"
          :hash="hash"
          @close="messageScreen = false"
        />
      </div>
      <div v-if="pending">
        <Loader size="25px" />
      </div>
      <div
        v-if="error"
        class="text-center error-info"
        style="max-width: 400px; margin: auto"
      >
        {{ $t('paymentStatus.unableToGetData') }}
      </div>
      <div
        v-if="bankAccountSelectError"
        class="text-center error-info"
        style="max-width: 400px; margin: auto"
      >
        {{ $t('paymentStatus.selectAccountError') }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/api/axios';
import SmoothReflow from '@/components/utils/SmoothReflow';
import MessageForm from '@/modules/pay/MessageForm';
import BasketGroup from '@/modules/payer/components/BasketGroup';
import { accountNumber } from '@/utils/filters';
import moment from 'moment';

export default {
  props: {
    id: String,
    date: String,
    hash: String,
  },
  data: () => ({
    error: false,
    paying: true,
    pending: false,
    messageScreen: false,
    paymentStartError: false,
    expanded: null,
    payRedirectPending: false,
    payers: null,
    bills: [],
    bankAccountSelectError: false,
  }),
  components: {
    MessageForm,
    SmoothReflow,
    BasketGroup,
  },
  computed: {
    groups() {
      return this.bills
        .map((x) => ({
          ...x,
          value: x.amount - x.paid,
        }))
        .filter((x) => x.value > 0)
        .sort((a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix())
        .reduce((acc, curr) => {
          const payeeId = this.payers && this.payers.length > 0 ? this.payers[0]?.payeeId : '';
          const group = `${(curr.accountNumber || '-')}_${payeeId}_${curr.gatewayId}_${curr.currency}`;

          if (acc[group]) {
            acc[group].list.push(curr);
            acc[group].value += curr.value;
          } else {
            acc[group] = {
              key: group,
              gatewayId: curr.gatewayId,
              accountNumber: curr.accountNumber,
              currency: curr.currency,
              payeeId: curr.payeeId,
              value: curr.value,
              list: [curr],
            };
          }
          return acc;
        }, {});
    },
  },
  methods: {
    accountNumber,
  },
  created() {
    this.pending = true;
    axios
      .get(`user/bills/notification/${this.id}/${this.date}/${this.hash}`)
      .then(({ data }) => {
        this.payers = data.payers;
        this.bills = data.bills;
        this.pending = false;

        const payeeId = this.payers && this.payers.length > 0 ? this.payers[0]?.payeeId : '';

        if (this.bills.length === 1 && this.bills[0].accountNumber) {
          this.$store.commit('toggleBillBasket', {
            payeeId,
            payerId: this.bills[0].payerId,
            billId: this.bills[0].billId,
            title: this.bills[0].title,
            gatewayId: this.bills[0].gatewayId,
            accountNumber: this.bills[0].accountNumber,
            value: Math.max(0, this.bills[0].amount - this.bills[0].paid),
            currency: this.bills[0].currency,
          });
        }

        this.$store.commit('setCurrencies', data.currencies);
      })
      .catch(() => {
        this.pending = false;
        this.error = true;
      });
  },
};
</script>

<style lang="scss" scoped>

.content {
  max-width: 1000px;
  margin: auto;
  padding-bottom: 50px;
}

.error-info {
  font-weight: 500;
  color: #888;
}

.navbar {
  height: 100px;
  .logo {
    height: 50px;
  }
}

.internal-payment-info ::v-deep b {
  font-weight: 600;
}

.setting-description {
  border-radius: 15px;
  border: 2px solid $blue;
  padding: 0.7rem;
  background-color: rgba(lighten($darkblue, 57), 0.9);
  color: $darkblue;
  width: 300px

  i {
    font-size: 0.85rem;
  }

  .info-wrapper {
    background-color: $darkblue;
    border-radius: 50%;
    color: lighten($darkblue, 57);
    text-align: center;
    width: 1.3rem;
    height: 1.3rem;
    margin-right: 0.7rem;
  }
}

.info-icon {
  color: $light-gray;
  cursor: pointer;
  &:hover {
    color: #333;
  }
}

.container-fluid {
  background-color: #fefefe !important;
}
</style>
